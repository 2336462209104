


























































































import { mapGetters } from 'vuex';

const ImagePreviewDialog = (): Promise<any> =>
  import('@/components/ImagePreviewDialog.vue');
const CopyAddress = (): Promise<any> =>
  import('@/components/CopyAddress.vue');
const VStatus = (): Promise<any> => import('@/components/VStatus.vue');
const ConfirmationDialog = (): Promise<any> =>
  import('@/components/ConfirmationDialog.vue');
const CopyStakeStreamerDialog = (): Promise<any> =>
  import('@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamerDialog.vue');

import { deleteStreamer, getStreamersList } from '@/api/CopyStake';
import { TStreamer, EStreamerStatus, EStreamerType } from '@/api/schema';
import { getLangLabel } from '@/helpers/langHelpers';
import { addThumbnailPrefix } from '@/helpers/copyStakeHelpers';
import { errorToastMessage } from '@/helpers/errorToastMessage';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};
const streamerTypeMap = {
  [EStreamerType.PRIVATE]: {
    label: 'Private',
    type: 'system-success'
  },
  [EStreamerType.PUBLIC]: {
    label: 'Public',
    type: 'additional-finished'
  }
};

export default {
  name: 'CopyStakeStreamerView',
  props: {
    hasCredentials: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImagePreviewDialog,
    CopyStakeStreamerDialog,
    ConfirmationDialog,
    CopyAddress,
    VStatus
  },
  data(): any {
    return {
      streamerTypeMap,
      isLoading: false,
      streamersList: [],
      total: null,
      options: { ...defaultOptions },
      dialogs: {
        imagePreview: false,
        edit: false,
        delete: false,
        loading: false,
        data: undefined
      }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    canEdit(): boolean {
      return this.$role.can.update('streamers');
    },
    headers(): any {
      return [
        { text: 'Wallet Address', value: 'playerWalletHash' },
        { text: 'Operator User ID', value: 'operatorUserId' },
        { text: 'Date Added', value: 'createdAt' },
        { text: 'Nickname', value: 'nickname' },
        { text: 'Channel ID', value: 'channelId' },
        { text: 'Language', value: 'language' },
        { text: 'Type', value: 'type' },
        { text: 'Thumbnail', value: 'image' },
        ...[
          this.canEdit
            ? {
                text: 'Action',
                value: 'actions',
                width: 140,
                align: 'center'
              }
            : null
        ]
      ].filter(Boolean);
    },
    streamerImage(): string {
      return this.dialogs.data?.image || '';
    }
  },
  watch: {
    operatorId: {
      handler: 'fetchStreamersData',
      immediate: true
    }
  },
  methods: {
    getLangLabel,
    addThumbnailPrefix,
    showDeleteDialog(streamer: TStreamer): void {
      this.dialogs.delete = true;
      this.dialogs.data = {
        disclaimer: `
          <p>
            Are you sure you want to delete streamer with nickname <strong>${
              streamer.nickname || streamer.playerId
            }</strong>?
          </p>
          <p class="text--disabled">You will not be able to recover it.</p>`,
        icon: 'mdi-alert-octagon',
        header: 'Delete Streamer',
        okText: 'Delete',
        streamer
      };
    },
    handlerOpenGameImagePreviewDialog(payload: TStreamer): void {
      this.dialogs.imagePreview = true;
      this.dialogs.data = payload;
    },
    checkIsOnline(status: EStreamerStatus): boolean {
      return status === EStreamerStatus.ONLINE;
    },
    async handlerOnDeleteStreamer(): Promise<void> {
      try {
        this.dialogs.loading = true;

        await deleteStreamer(this.dialogs.data.streamer.id);
        this.fetchStreamersData();
        this.$toast.success(
          `Streamer "${this.dialogs.data.streamer.nickname}" was successfully deleted`
        );

        this.closeDialog('delete');
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.dialogs.loading = false;
      }
    },
    handlerOnEditStreamer(streamer?: TStreamer): void {
      this.dialogs.edit = true;

      if (streamer) {
        this.dialogs.data = streamer;
      }
    },
    closeDialog(type: string): void {
      this.dialogs[type] = false;
      this.$nextTick(() => {
        this.dialogs.data = {};
      });
    },
    async fetchStreamersData(): Promise<void> {
      if (!this.operatorId) return;

      try {
        this.isLoading = true;

        const { content, totalElements } = await getStreamersList({
          size: this.options.itemsPerPage,
          page: this.options.page - 1,
          operatorId: this.operatorId
        });

        this.streamersList = content;
        this.total = totalElements;
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
