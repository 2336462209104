













export default {
  name: 'CopyStakeDisclaimer'
};
